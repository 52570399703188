<ng-container *transloco="let t, read: 'line.lineBoolPropList'">

  <span><strong>{{line.startingPosition | transloco}}</strong></span>
  <span *ngIf="line.eliminate">{{t('eliminate')}}</span>
  <span *ngIf="line.traverse">{{t('traverse')}}</span>
  <span *ngIf="line.highball">{{t('highball')}}</span>
  <span *ngIf="line.lowball">{{t('lowball')}}</span>
  <span *ngIf="line.morpho">{{t('morpho')}}</span>
  <span *ngIf="line.noTopout">{{t('noTopout')}}</span>
  <span *ngIf="line.badDropzone">{{t('badDropzone')}}</span>
  <span *ngIf="line.childFriendly">{{t('childFriendly')}}</span>

  <span *ngIf="line.roof">{{t('roof')}}</span>
  <span *ngIf="line.overhang">{{t('overhang')}}</span>
  <span *ngIf="line.vertical">{{t('vertical')}}</span>
  <span *ngIf="line.slab">{{t('slab')}}</span>

  <span *ngIf="line.athletic">{{t('athletic')}}</span>
  <span *ngIf="line.technical">{{t('technical')}}</span>
  <span *ngIf="line.endurance">{{t('endurance')}}</span>
  <span *ngIf="line.cruxy">{{t('cruxy')}}</span>
  <span *ngIf="line.dyno">{{t('dyno')}}</span>

  <span *ngIf="line.crack">{{t('crack')}}</span>
  <span *ngIf="line.dihedral">{{t('dihedral')}}</span>
  <span *ngIf="line.compression">{{t('compression')}}</span>
  <span *ngIf="line.arete">{{t('arete')}}</span>
  <span *ngIf="line.mantle">{{t('mantle')}}</span>

  <span *ngIf="line.jugs">{{t('jugs')}}</span>
  <span *ngIf="line.sloper">{{t('sloper')}}</span>
  <span *ngIf="line.crimps">{{t('crimps')}}</span>
  <span *ngIf="line.pockets">{{t('pockets')}}</span>
  <span *ngIf="line.pinches">{{t('pinches')}}</span>

</ng-container>
