<ng-container *transloco="let t, read: 'forgotPasswordCheckMailbox'">

  <div class="auth-container">

    <div class="card login-card">

      <h3>{{t('title')}}</h3>

      {{t('description')}}

    </div>

  </div>

</ng-container>
