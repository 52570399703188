<ng-container *transloco="let t, read: 'gpsButton'">

  <p-splitButton label="{{t('gps')}}"
                 icon="pi pi-map-marker"
                 text
                 (onClick)="openGPS()"
                 [model]="items">
  </p-splitButton>

</ng-container>
