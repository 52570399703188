<div class="content">

  <p-card header="{{area?.name}}">

    <lc-secret-spot-tag *ngIf="area?.secret"></lc-secret-spot-tag>

    <p-breadcrumb class="max-w-full" [model]="breadcrumbs" [home]="breadcrumbHome"></p-breadcrumb>

    <p-tabMenu [model]="items" [scrollable]="true" *ngIf="area" data-cy="area-tabs-menu"></p-tabMenu>

    <router-outlet name="areaContent"></router-outlet>

  </p-card>

</div>
