<ng-container *transloco="let t">

  <form [formGroup]="gpsForm" lcForm>

    <div class="flex flex-row gap-3">

      <div class="flex flex-column gap-2 flex-1" lcControlGroup>
        <label>{{t('latLabel')}}</label>
        <input type="text" pInputText formControlName="lat" placeholder="{{t('latPlaceholder')}}"
               lcFormControl/>
        <small class="p-error" *lcIfError="'invalidLat'">{{t('invalidLat')}}</small>
      </div>

      <div class="flex flex-column gap-2 flex-1" lcControlGroup>
        <label>{{t('lngLabel')}}</label>
        <input type="text" pInputText formControlName="lng" placeholder="{{t('lngPlaceholder')}}"
               lcFormControl/>
        <small class="p-error" *lcIfError="'invalidLng'">{{t('invalidLng')}}</small>
      </div>

      <div class="flex flex-column gap-2">
        <label>&nbsp;</label>
        <p-button label="{{t('getGPSPosition')}}" icon="pi pi-map-marker" (click)="getGeoLocation()"
                  class="responsive-button"
                  [loading]="positionLoading"></p-button>
      </div>

    </div>

  </form>

  <div class="mt-3" *ngIf="gpsLoadingSuccess || gpsLoadingError">
    <p-message *ngIf="gpsLoadingSuccess && !positionLoading" severity="success"
               text="{{t('gpsLoadedSuccessfullyWithAccuracyX', {x: accuracy})}}"></p-message>
    <p-message *ngIf="gpsLoadingSuccess && positionLoading" severity="success"
               text="{{t('gpsLoadedSuccessfullyWithAccuracyXTryingAccuracyImprovement', {x: accuracy})}}"></p-message>
    <p-message *ngIf="gpsLoadingError" severity="error" text="{{t('gpsCouldNotBeLoaded')}}"></p-message>
  </div>

</ng-container>
