<ng-container *transloco="let t, read: 'topoImage.topoImageDetail'">

  <div *ngIf="topoImage.title || (topoImage.gps && topoImage.gps)"
       class="flex justify-content-center align-items-center mt-2">
    <div class="flex-grow-1 flex block-title">
      <h5 class="mt-0 mb-0 inline-block" *ngIf="topoImage.title">{{topoImage.title}}</h5>
    </div>
    <lc-gps-button [gps]="topoImage.gps" *ngIf="topoImage.gps"></lc-gps-button>
  </div>

  <section *ngIf="topoImage.description"
           class="text-xs flex justify-content-center">
    <div [innerHTML]="topoImage.description | sanitizeHtml"></div>
  </section>

</ng-container>
