<ng-container *transloco="let t, read: 'accountForm'">

  <div class="auth-container">

    <div class="card login-card">



      <form [formGroup]="accountForm" lcForm>

        <div class="flex flex-row align-items-center">

          <lc-avatar-upload formControlName="avatar" ></lc-avatar-upload>

          <div class="ml-5">
            <h3>{{t('accountFormTitle')}}</h3>

            {{t('accountFormDescription')}}
          </div>

        </div>

        <div class="card-container block" lcControlGroup>
          <input type="text" pInputText formControlName="firstname" placeholder="{{t('firstnamePlaceholder')}}"
                 lcFormControl data-cy="register-form-firstname"/>
          <small class="p-error" *lcIfError="'required'">{{t('required')}}</small>
          <small class="p-error" *lcIfError="'maxlength'">{{t('maxlength120')}}</small>
        </div>

        <div class="card-container block" lcControlGroup>
          <input type="text" pInputText formControlName="lastname" placeholder="{{t('lastnamePlaceholder')}}"
                 lcFormControl data-cy="register-form-lastname"/>
          <small class="p-error" *lcIfError="'required'">{{t('required')}}</small>
          <small class="p-error" *lcIfError="'maxlength'">{{t('maxlength120')}}</small>
        </div>

        <div class="card-container block" lcControlGroup>
          <input type="text" pInputText formControlName="email" placeholder="{{t('emailPlaceholder')}}"
                 lcFormControl data-cy="register-form-email"/>
          <small class="p-error" *lcIfError="'required'">{{t('required')}}</small>
          <small class="p-error" *lcIfError="'pattern'">{{t('invalidEmailHint')}}</small>
          <small class="p-error" *lcIfError="'emailTaken'">{{t('emailTaken')}}</small>
          <small class="p-error" *lcIfError="'maxlength'">{{t('maxlength120')}}</small>
        </div>

      </form>

      <p-button label="{{t('saveAccountSettingsButtonLabel')}}" (click)="save()"
                [loading]="loadingState === loadingStates.LOADING"></p-button>

      <p-messages severity="info" class="w-full max-w-35rem" *ngIf="emailChanged">
        <ng-template pTemplate>
          {{t('emailAddressChangeInfoText')}}
        </ng-template>
      </p-messages>

    </div>

  </div>

</ng-container>
