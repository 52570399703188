<ng-container *transloco="let t, read: 'sector.sectorList'">

  <p-dataView #dv [value]="sectors" [sortField]="sortField" [sortOrder]="sortOrder"
              [emptyMessage]="t('noSectorsFoundEmptyMessage')"
              [loading]="loading === loadingStates.LOADING">
    <ng-template pTemplate="header">
      <div class="flex flex-column sm:flex-row justify-content-between gap-2">
        <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey"
                    optionLabel="label" (onChange)="onSortChange($event)"
                    styleClass="md:mb-0">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="sortKey">
              <i [class]="sortKey.icon" *ngIf="sortKey.icon"></i>
              <div>{{ sortKey.label }}</div>
            </div>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <div class="flex align-items-center gap-2">
              <i [class]="option.icon" *ngIf="option.icon"></i>
              <div>{{ option.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <div class="flex justify-content-start sm:justify-content-end gap-3 ">
          <p-button label="{{t('newSectorButtonLabel')}}" icon="pi pi-plus" class="responsive-button"
                    routerLink="/topo/{{cragSlug}}/create-sector"
                    *isModerator="true"></p-button>
          <p-button label="{{t('reorderSectorsButtonLabel')}}" icon="pi pi-sort" (click)="reorderSectors()"
                    [disabled]="sectors?.length < 2" class="responsive-button"
                    *isModerator="true"></p-button>
        </div>
      </div>
    </ng-template>
    <ng-template let-sectors pTemplate="list">
      <div class="grid grid-nogutter">

        <div class="col-12 clickable-data-item" routerLink="/topo/{{cragSlug}}/{{sector.slug}}/areas"
             *ngFor="let sector of sectors; let first = first" data-cy="sector-list-item">
          <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4"
               [ngClass]="{ 'border-top-1 surface-border': !first }">
            <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round"
                 [src]="sector.portraitImage ? sector.portraitImage.thumbnailM : 'assets/placeholder.png'"
                 [alt]="sector.name"/>
            <div
              class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
              <div class="flex flex-column align-items-center sm:align-items-start gap-3  w-full">
                <span class="text-2xl font-bold black-font">{{ sector.name }}</span>
                <div [innerHTML]="sector.shortDescription | sanitizeHtml"  *ngIf="sector.shortDescription"  class="quill-content"></div>
                <lc-leveled-grade-distribution #gradeDistribution [fetchingObservable]="sectorsService.getSectorGrades(sector.slug)"></lc-leveled-grade-distribution>
                <lc-ascent-count [ascentCount]="sector.ascentCount"></lc-ascent-count>
                <lc-secret-spot-tag *ngIf="sector.secret"></lc-secret-spot-tag>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>

</ng-container>
