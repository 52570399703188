<ng-container *transloco="let t">

  <p-skeleton width="100%" height="2rem" *ngIf="!grades"></p-skeleton>

  <div class="grade-distribution-stack hidden sm:flex" *ngIf="grades">
    <div class="level-total">{{grades.length - level5}} {{t('lines')}}:</div>
    <div class="level-1" [style.width]="grades.length > 0 ? (level1 / grades.length) * 100 + '%' : '20%'">
      <p-badge [value]="level1 | toString" styleClass="neutral-badge"></p-badge>
      {{t('leveledGradeDistributionLevel1')}}
    </div>
    <div class="level-2" [style.width]="grades.length > 0 ? (level2 / grades.length) * 100 + '%' : '20%'">
      <p-badge [value]="level2 | toString" styleClass="neutral-badge"></p-badge>
      {{t('leveledGradeDistributionLevel2')}}
    </div>
    <div class="level-3" [style.width]="grades.length > 0 ? (level3 / grades.length) * 100 + '%' : '20%'">
      <p-badge [value]="level3 | toString" styleClass="neutral-badge"></p-badge>
      {{t('leveledGradeDistributionLevel3')}}
    </div>
    <div class="level-4" [style.width]="grades.length > 0 ? (level4 / grades.length) * 100 + '%' : '20%'">
      <p-badge [value]="level4 | toString" styleClass="neutral-badge"></p-badge>
      {{t('leveledGradeDistributionLevel4')}}
    </div>
    <div class="level-5" [style.width]="grades.length > 0 ? (level5 / grades.length) * 100 + '%' : '20%'">
      <p-badge [value]="level5 | toString" styleClass="neutral-badge"></p-badge>
      {{t('leveledGradeDistributionLevel5')}}
    </div>
  </div>

  <div class="grade-distribution-tags flex align-items-center gap-3 sm:hidden" *ngIf="grades">
    <p-tag styleClass="level-total">
      <p-badge [value]="grades.length - level5" styleClass="neutral-badge"></p-badge>
       {{t('lines')}}:
    </p-tag>
    <p-tag styleClass="level-1">
      <p-badge [value]="level1 | toString" styleClass="neutral-badge"></p-badge>
      {{t('leveledGradeDistributionLevel1')}}
    </p-tag>
    <p-tag styleClass="level-2">
      <p-badge [value]="level2 | toString" styleClass="neutral-badge"></p-badge>
      {{t('leveledGradeDistributionLevel2')}}
    </p-tag>
    <p-tag styleClass="level-3">
      <p-badge [value]="level3 | toString" styleClass="neutral-badge"></p-badge>
      {{t('leveledGradeDistributionLevel3')}}
    </p-tag>
    <p-tag styleClass="level-4">
      <p-badge [value]="level4 | toString" styleClass="neutral-badge"></p-badge>
      {{t('leveledGradeDistributionLevel4')}}
    </p-tag>
    <p-tag styleClass="level-5">
      <p-badge [value]="level5 | toString" styleClass="neutral-badge"></p-badge>
      {{t('leveledGradeDistributionLevel5')}}
    </p-tag>
  </div>

</ng-container>
