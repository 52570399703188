<ng-container *transloco="let t, read: 'crag.cragList'">

  <p-dataView #dv [value]="crags" [sortField]="sortField" [sortOrder]="sortOrder"
              [emptyMessage]="t('noCragsFoundEmptyMessage')"
              [loading]="loading === loadingStates.LOADING">
    <ng-template pTemplate="header">
      <div class="flex flex-column sm:flex-row justify-content-between gap-2">
        <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey"
                    optionLabel="label" (onChange)="onSortChange($event)"
                    styleClass="md:mb-0">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="sortKey">
              <i [class]="sortKey.icon" *ngIf="sortKey.icon"></i>
              <div>{{ sortKey.label }}</div>
            </div>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <div class="flex align-items-center gap-2">
              <i [class]="option.icon" *ngIf="option.icon"></i>
              <div>{{ option.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <div class="flex justify-content-start sm:justify-content-end gap-3 ">
          <p-button label="{{t('newCragButtonLabel')}}" icon="pi pi-plus" routerLink="/topo/create-crag"
                    *isModerator="true" class="responsive-button"></p-button>
          <p-button label="{{t('reorderCragsButtonLabel')}}" icon="pi pi-sort" (click)="reorderCrags()"
                    [disabled]="crags?.length < 2" class="responsive-button"
                    *isModerator="true"></p-button>
        </div>
      </div>
    </ng-template>
    <ng-template let-crags pTemplate="list">
      <div class="grid grid-nogutter">
        <div class="col-12 clickable-data-item" routerLink="/topo/{{crag.slug}}/sectors"
             *ngFor="let crag of crags; let first = first"  data-cy="crag-list-item">
          <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4"
               [ngClass]="{ 'border-top-1 surface-border': !first }">
            <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round"
                 [src]="crag.portraitImage ? crag.portraitImage.thumbnailM : 'assets/placeholder.png'"
                 [alt]="crag.name"/>
            <div
              class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
              <div class="flex flex-column align-items-center sm:align-items-start gap-3 w-full">
                <span class="text-2xl font-bold black-font">{{ crag.name }}
                </span>
                <div [innerHTML]="crag.shortDescription | sanitizeHtml" *ngIf="crag.shortDescription"  class="quill-content"></div>
                <lc-leveled-grade-distribution
                  [fetchingObservable]="cragsService.getCragGrades(crag.slug)"></lc-leveled-grade-distribution>
                <lc-ascent-count [ascentCount]="crag.ascentCount"></lc-ascent-count>
                <lc-secret-spot-tag *ngIf="crag.secret"></lc-secret-spot-tag>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>

</ng-container>
