<ng-container *transloco="let t, read: 'forgotPassword'">

  <div class="auth-container">

    <div class="card login-card">

      <h3>{{t('title')}}</h3>

      {{t('description')}}

      <form [formGroup]="forgotPasswordForm" lcForm>

        <div class="card-container block" lcControlGroup>
          <input formControlName="email" pInputText placeholder="{{t('emailPlaceholder')}}" lcFormControl>
          <small class="p-error" *lcIfError="'required'">{{t('required')}}</small>
        </div>

      </form>

      <p-button label="{{t('sendEmailButtonLabel')}}" (click)="requestResetPasswordMail()"
                [loading]="(loadingState$ | async) === loadingStates.LOADING">
      </p-button>

    </div>

  </div>

</ng-container>
