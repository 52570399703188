<ng-container *transloco="let t, read: 'changeEmail'">

  <div class="auth-container">

    <div class="card login-card">

      <h3>{{t('changeEmailTitle')}}</h3>

      <p-progressSpinner ariaLabel="loading" *ngIf="loading"></p-progressSpinner>

      <p-message severity="success" text="{{t('emailChangedSuccessfully')}}" *ngIf="!loading && !error">
      </p-message>

      <p-message severity="error" text="{{t('emailChangeError')}}" *ngIf="!loading &&error">
      </p-message>

      <p-button label="{{t('takeMeToTheRock')}}" routerLink="/topo" *ngIf="!loading && !error"></p-button>

    </div>

  </div>

</ng-container>
