<ng-container *transloco="let t, read: 'notFound'">

  <div class="auth-container">

    <div class="card login-card">

      <h3>{{t('title')}}</h3>

      <div [innerHTML]="t('description', {url})"></div>

    </div>

  </div>

</ng-container>
