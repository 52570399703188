<ng-container *transloco="let t, read: 'line.line'">

  <div class="flex flex-column xl:flex-row xl:align-items-start gap-4" *ngIf="line && ticks">

    <div class="w-12 sm:w-9 xl:w-5  flex flex-column mx-auto pt-4 gap-3 ">
      <div *ngFor="let topoImage of line.topoImages">
        <lc-topo-image class=" shadow-2 flex  border-round"
                       [topoImage]="topoImage"></lc-topo-image>
        <lc-topo-image-details [topoImage]="topoImage"></lc-topo-image-details>
      </div>
      <img class="block  border-round"
           [src]="'assets/placeholder.png'"
           *ngIf="line.topoImages.length === 0"
           [alt]="line.name"/>
      <p-button label="{{t('reorderLinePathsForLineButtonLabel')}}" icon="pi pi-sort" (click)="reorderLinePaths()"
                [disabled]="line.topoImages?.length < 2"
                styleClass=" p-button-sm"
                *isModerator="true"></p-button>
    </div>

    <div
      class="flex flex-column  justify-content-between align-items-center xl:align-items-start flex-1 gap-4 pt-4">
      <lc-line-bool-prop-list [line]="line"></lc-line-bool-prop-list>

      <div [innerHTML]="line.description | sanitizeHtml" *ngIf="line.description" class="quill-content line-description"></div>
      <div>{{t('ascentCount')}}: {{line.ascentCount}}</div>
      <div *ngIf="line.videos?.length > 0">{{t('videoBeta')}}:
        <span class="link-list">
        <span *ngFor="let video of line.videos"><a [href]="video.url" target="_blank">{{video.title}}</a></span>
      </span>
      </div>
      <div>{{t('rating')}}:
        <p-rating [ngModel]="line.rating" [cancel]="false" [readonly]="true"></p-rating>
      </div>
      <div *ngIf="line.faName || line.faYear" class="fa">{{t('FA')}} <span
        *ngIf="line.faYear">{{line.faYear}}</span><span *ngIf="line.faName">{{line.faName}}</span></div>
      <ng-container *ngIf="!ticks.has(line.id)">
        <lc-todo-button [line]="line" [isTodo]="todos.has(line.id)" [showLabel]="true" *isLoggedIn="true"></lc-todo-button>
      </ng-container>
      <lc-tick-button [line]="line" [ticked]="ticks.has(line.id)" [showLabel]="true" *isLoggedIn="true"></lc-tick-button>
    </div>

  </div>

  <ng-container *ngIf="!line && !ticks">

    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="90%" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="87%" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="70%" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="65%" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="45%" styleClass="mb-4"></p-skeleton>
    <p-skeleton height="4rem" styleClass="mb-4"></p-skeleton>
    <p-skeleton width="40%" height="4rem"></p-skeleton>

  </ng-container>

</ng-container>
